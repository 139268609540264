<script setup lang="ts">
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import { pluralize } from '/~/utils/format/string'
import { useLocalization } from '/~/composables/localization'
import { type TSummary } from '/~/templates/bill-payments-v1_5/modals/payments-make/payments-make-summary.mdl.vue'

const props = defineProps<{
  payingTo: TSummary['payingTo']
}>()

const isBatchOrderSelected = computed(() => props.payingTo.batchOrder)
const isStatementSelected = computed(() => props.payingTo.statement)
const isProviderPayee = computed(
  () => props.payingTo.payee?.isProviderPayee && !isStatementSelected.value
)
const isBankAccountSelected = computed(
  () => props.payingTo.payee?.type === 'bankAccount'
)
const isBPaySelected = computed(() => props.payingTo.payee?.type === 'bpay')

const mapPayingTo = computed(() => {
  if (isBatchOrderSelected.value) {
    const payeeCount =
      props.payingTo.batchOrder?.transactionsStatusCount.reduce((acc, item) => {
        return acc + item.count
      }, 0)
    const batchFileTypeTitle =
      props.payingTo.batchOrder?.type === 'payroll'
        ? 'Payroll file'
        : 'Batch file'

    return {
      name: `${batchFileTypeTitle}: ${props.payingTo.batchOrder?.fileName}`,
      payeeCount: `(${payeeCount} ${pluralize(payeeCount, 'payment')})`,

      description: `Description: ${props.payingTo.batchOrder?.reference}`,
    }
  } else if (isStatementSelected.value) {
    return {
      name: props.payingTo.providerTitle,
      accountName: `Account Name: ${props.payingTo.statement?.raw?.userStatementAccount?.name}`,
      accountNumber: `Account No: ${props.payingTo.statement?.raw?.userStatementAccount?.number}`,
    }
  } else if (isProviderPayee.value) {
    return {
      name: props.payingTo.providerTitle,
      accountName: `Account Name: ${props.payingTo.payee?.accountName}`,
      accountNumber: `Account No: ${props.payingTo.payee?.accountNumber}`,
      description: `Description: ${props.payingTo.description}`,
      reference: `Reference: ${props.payingTo.reference}`,
    }
  } else if (isBankAccountSelected.value) {
    const { parseBsb } = useLocalization()

    return {
      name: props.payingTo.payee?.name,
      ...parseBsb(props.payingTo.payee?.bsb, { showLabel: true }),
      accountNumber: `Account No: ${props.payingTo.payee?.accountNumber}`,
      description: `Description: ${props.payingTo.description}`,
      reference: `Reference: ${props.payingTo.reference}`,
    }
  } else if (isBPaySelected.value) {
    return {
      name: props.payingTo.payee?.name,
      billerCode: `Biller Code: ${props.payingTo.payee?.billerCode}`,
      refBpay: `Reference: ${props.payingTo.payee?.reference}`,
      description: `Description: ${props.payingTo.description}`,
    }
  }

  return undefined
})

const fields = computed(() => {
  const potentialFields = [
    'name',
    'bsb',
    'institutionNumber',
    'transitNumber',
    'billerCode',
    'refBpay',
    'accountName',
    'accountNumber',
    'description',
    'reference',
  ]

  return potentialFields.filter((field) => Boolean(mapPayingTo.value?.[field]))
})
</script>

<template>
  <div class="flex flex-col gap-2 py-5">
    <div class="font-bold">Paying to</div>
    <template v-if="!isBatchOrderSelected">
      <div v-for="item in fields" :key="item">
        <div class="w-10/12 truncate text-eonx-neutral-600">
          {{ mapPayingTo[item] }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex w-10/12 flex-wrap text-eonx-neutral-600">
        <div class="truncate pr-1">{{ mapPayingTo?.name }}</div>
        <div>{{ mapPayingTo?.payeeCount }}</div>
      </div>
      <div
        v-if="mapPayingTo?.description"
        class="flex flex-wrap text-eonx-neutral-600"
      >
        {{ mapPayingTo?.description }}
      </div>
    </template>
    <div class="flex justify-between">
      <div class="font-bold">Amount</div>
      <div class="font-bold">
        {{ formatDollar(payingTo.amount) }}
      </div>
    </div>
  </div>
</template>
