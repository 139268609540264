<script setup lang="ts">
import { computed, ref } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'

const props = withDefaults(
  defineProps<{
    loading?: boolean
    action?: () => Promise<void> | void
    hide?: () => void
    actionButtonLabel?: string
  }>(),
  {
    loading: false,
    action: () => {},
    hide: () => {},
    actionButtonLabel: 'Pay',
  }
)

const paying = ref(false)

const processing = computed(() => paying.value || props.loading)

async function pay() {
  paying.value = true
  await props.action()
  paying.value = false
}
</script>

<template>
  <div class="flex justify-between gap-x-2.5 pt-6">
    <base-button
      class="w-1/2"
      color="primary"
      look="outlined-color"
      :disabled="processing"
      @click="props.hide"
    >
      Back
    </base-button>
    <base-button
      class="w-1/2"
      :disabled="processing"
      :loading="processing"
      @click="pay"
    >
      {{ actionButtonLabel }}
    </base-button>
  </div>
</template>
