<script setup lang="ts">
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { type TSummary } from '/~/templates/bill-payments-v1_5/modals/payments-make/payments-make-summary.mdl.vue'

const props = withDefaults(
  defineProps<{
    programFeesData: TSummary['programFeesData']
    payingMethodType?: PaymentMethodType
    processingFee?: number
    percentageFeeRate?: number
    payingToType?: string
  }>(),
  {
    processingFee: 0,
    percentageFeeRate: 0,
    payingToType: '',
  }
)

const totalFees = computed(() => {
  return props.programFeesData.totalFees + props.processingFee
})

const hasSeparateTransaction = computed(() => {
  return (
    props.payingMethodType === PaymentMethodType.bankAccount &&
    props.payingToType === 'statement'
  )
})

const processingFeeLabel = computed(() => {
  let label = 'Processing fee*'

  if (props.percentageFeeRate) {
    label += ` (${props.percentageFeeRate}%)`
  }

  return label
})

function getProgramFeeLabel(programFee) {
  let label = `${programFee.label.toLowerCase()} (`

  if (parseFloat(programFee.percentage) > 0) {
    label += `${programFee.percentage}% `
  }

  label += 'inc. GST)'

  return label
}
</script>

<template>
  <div class="flex flex-col gap-2 py-5">
    <div class="font-bold">Fees</div>

    <template v-for="programFee in programFeesData.fees">
      <div
        v-if="programFee.fee > 0"
        :key="programFee.label"
        class="flex justify-between"
      >
        <div class="text-eonx-neutral-600 first-letter:uppercase">
          {{ getProgramFeeLabel(programFee) }}
        </div>
        <div class="text-sm">{{ formatDollar(programFee.fee) }}</div>
      </div>
    </template>

    <div v-if="processingFee" class="flex justify-between">
      <div class="text-eonx-neutral-600">{{ processingFeeLabel }}</div>
      <div class="text-sm">{{ formatDollar(processingFee) }}</div>
    </div>

    <div class="flex items-center justify-between">
      <div class="font-bold">Total fees</div>
      <div class="font-bold">
        {{ formatDollar(totalFees) }}
      </div>
    </div>
    <template v-if="percentageFeeRate">
      <div class="text-sm text-eonx-neutral-600">
        *Processing fee is charged on amount plus program fee.
      </div>
    </template>
    <div v-if="hasSeparateTransaction" class="text-sm text-eonx-neutral-600">
      Total fees will be charged as a separate transaction.
    </div>
  </div>
</template>
